// a flag to toggle asset pipeline / compass integration
// defaults to true if twbs-font-path function is present (no function => twbs-font-path('') parsed as string == right side)
// in Sass 3.3 this can be improved with: function-exists(twbs-font-path)
:root {
--ion-bootstrap-sass-asset-helper: (twbs-font-path("") != unquote('twbs-font-path("")'));
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.



    --ion-gray-darker:            #333; // #222
    --ion-gray-dark:              #4D4D4D; // #333
    --ion-gray:                   lighten(#000, 33.5%); // #555
    --ion-gray-light:             lighten(#000, 60%); // #999
    --ion-gray-lighter:           lighten(#000, 93.5%); // eee

    --ion-brand-primary:         #459EEC;
    --ion-brand-primary-light:   lighten(#459EEC, 10%);
    --ion-brand-success:         #2FD189;
    --ion-brand-info:            var(--ion-brand-primary);
    --ion-brand-warning:         #F29400;
    --ion-brand-danger:          #E94D4C;
    --ion-brand-danger-light:    lighten(#E94D4C, 10%);


    //== Scaffolding
    //
    // ## Settings for some of the most global styles.

    //** Background color for `<body>`.
    --ion-body-bg:               white;
    //** Global text color on `<body>`.
    --ion-text-color:            var(--ion-gray-dark);

    //** Global textual link color.
    --ion-link-color:            #459EEC;

    --ion-link-hover-color:      lighten(#459eec, 5%);


    //== Typography
    //
    //## Font, line-height, and color for body text, headings, and more.

    --ion-font-family-sans-serif:  Lato, "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
    --ion-font-family-serif:       "Merriweather", Georgia, "Times New Roman", Times, serif;
    //** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
    --ion-font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
    --ion-font-family-base:        var(--ion-font-family-sans-serif);

    --ion-font-size-base:          15px;
    --ion-font-size-large:         ceil((var(--ion-font-size-base * 1.25))); // ~18px
    --ion-font-size-small:         ceil((var(--ion-font-size-base * 0.85))); // ~12px

    --ion-font-size-h1:            floor((var(--ion-font-size-base * 2.6))); // ~36px
    --ion-font-size-h2:            floor((var(--ion-font-size-base * 2.15))); // ~30px
    --ion-font-size-h3:            ceil((var(--ion-font-size-base * 1.5))); // ~24px
    --ion-font-size-h4:            ceil((var(--ion-font-size-base * 1.25))); // ~18px
    --ion-font-size-h5:            var(var(--ion-font-size-base));
    --ion-font-size-h6:            ceil((var(--ion-font-size-base * 0.85))); // ~12px

    //** Unit-less `line-height` for use in components like buttons.
    --ion-line-height-base:        1.6;
    //** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
    --ion-line-height-computed:    floor(var(--ion-font-size-base) * var(--ion-line-height-base)); // ~24px

    //** By default, this inherits from the `<body>`.
    --ion-headings-font-family:    inherit;
    --ion-headings-font-weight:    400;
    --ion-headings-line-height:    1.2;
    --ion-headings-color:          var(--ion-gray-dark);


    //-- Iconography
    //
    //## Specify custom locations of the include Glyphicons icon font. Useful for those including Bootstrap via Bower.

    // --ion-icon-font-path: "app/printix-libs/shared-assets/fonts/icons";
    --ion-icon-font-path: "src/assets/fonts/icons";
    // "icons":          "icons";
    --ion-icon-font-svg-id:				"glyphicons_halflingsregular";

    //== Components
    //
    //## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

    --ion-padding-base-vertical:     6px;
    --ion-padding-base-horizontal:   12px;

    --ion-padding-large-vertical:    10px;
    --ion-padding-large-horizontal:  16px;

    --ion-padding-small-vertical:    5px;
    --ion-padding-small-horizontal:  10px;

    --ion-padding-xs-vertical:       1px;
    --ion-padding-xs-horizontal:     5px;

    --ion-line-height-large:         1.33;
    --ion-line-height-small:         1.5;

    --ion-border-radius-base:        4px;
    --ion-border-radius-large:      var(--ion-border-radius-base);
    --ion-border-radius-small:       3px;

    //** Global color for active items (e.g., navs or dropdowns).
    --ion-component-active-color:    var(--ion-brand-primary);
    //** Global background color for active items (e.g., navs or dropdowns).
    --ion-component-active-bg:       var(--ion-brand-primary);

    //** Width of the `border` for generating carets that indicator dropdowns.
    --ion-caret-width-base:          4px;
    //** Carets increase slightly in size for larger components.
    --ion-caret-width-large:         5px;


    //== Tables
    //
    //## Customizes the `.table` component with basic values, each used across all table variations.

    //** Padding for `<th>`s and `<td>`s.
    --ion-table-cell-padding:            8px;
    //** Padding for cells in `.table-condensed`.
    --ion-table-condensed-cell-padding:  5px;

    //** Default background color used for all tables.
    --ion-table-bg:                      transparent;
    //** Background color used for `.table-striped`.
    --ion-table-bg-accent:               #f9f9f9;
    //** Background color used for `.table-hover`.
    --ion-table-bg-hover:                #f5f5f5;
    --ion-table-bg-active:           var(--ion-table-bg-hover);

    //** Border color for table and cell borders.
    --ion-table-border-color:            #ddd;

    //== Buttons
    //
    //## For each of Bootstraps buttons, define text, background and border color.

    --ion-btn-font-weight:                600;

    --ion-btn-default-color:              #333;
    --ion-btn-default-bg:                 #fff;
    --ion-btn-default-border:             #ccc;

    --ion-btn-primary-color:              #fff;
    --ion-btn-primary-bg:                 #2FD189;
    --ion-btn-primary-bg-light:           #83e3b8; // --ion-btn-primary-bg lighten 20%
    --ion-btn-primary-bg-dark:            #1c7e52; // --ion-btn-primary-bg darken 20%
    --ion-btn-primary-border:             transparent;

    --ion-btn-success-color:              #fff;
    --ion-btn-success-bg:                 var(--ion-brand-success);
    --ion-btn-success-border:             darken(#2FD189, 5%); // --ion-brand-success

    --ion-btn-info-color:                 #fff;
    --ion-btn-info-bg:                    var(--ion-brand-info);
    --ion-btn-info-border:                darken(#459EEC, 5%); // --ion-btn-info-bg

    --ion-btn-warning-color:              #fff;
    --ion-btn-warning-bg:                 var(--ion-brand-warning);
    --ion-btn-warning-border:             darken(#F29400, 5%);
    // --ion-btn-warning-border:          LightenDarkenColor(var(--ion-btn-warning-bg), 5%);

    --ion-btn-danger-color:               #fff;
    --ion-btn-danger-bg:                  var(--ion-brand-danger);
    --ion-btn-danger-border:              darken(#E94D4C, 5%);
    // --ion-btn-danger-border:           LightenDarkenColor(var(--ion-btn-danger-bg), 5%);

    --ion-btn-link-disabled-color:    var(--ion-gray-light);


    //== Forms
    //
    //##

    //** `<input>` background color
    --ion-input-bg:                       #fff;
    //** `<input disabled>` background color
    --ion-input-bg-disabled:          var(--ion-gray-lighter);

    //** Text color for `<input>`s
    --ion-input-color:                var(--ion-gray);
    //** `<input>` border color
    --ion-input-border:                   #ccc;
    //** `<input>` border radius
    --ion-input-border-radius:           var(--ion-border-radius-base);
    //** Border color for inputs on focus
    --ion-input-border-focus:             #66afe9;

    //** Placeholder text color
    --ion-input-color-placeholder:    var(--ion-gray-light);

    //** Default `.form-control` height
    --ion-input-height-base:              (var(--ion-line-height-computed) + (var(--ion-padding-base-vertical) * 2) + 2);
    //** Large `.form-control` height
    --ion-input-height-large:             (ceil(var(--ion-font-size-large) * var(--ion-line-height-large)) + (var(--ion-padding-large-vertical) * 2) + 2);
    //** Small `.form-control` height
    --ion-input-height-small:             (floor(var(--ion-font-size-small) * var(--ion-line-height-small)) + (var(--ion-padding-small-vertical) * 2) + 2);

    --ion-legend-color:               var(--ion-gray-dark);
    --ion-legend-border-color:            #e5e5e5;

    //** Background color for textual input addons
    --ion-input-group-addon-bg:       var(--ion-gray-lighter);
    //** Border color for textual input addons
    --ion-input-group-addon-border-color: var(--ion-input-border);


    //== Dropdowns
    //
    //## Dropdown menu container and contents.

    //** Background for the dropdown menu.
    --ion-dropdown-bg:                    #fff;
    //** Dropdown menu `border-color`.
    --ion-dropdown-border:                #E9EBED;
    //** Dropdown menu `border-color` **for IE8**.
    --ion-dropdown-fallback-border:       #ccc;
    //** Divider color for between dropdown items.
    --ion-dropdown-divider-bg:            #e5e5e5;

    //** Dropdown link text color.
    --ion-dropdown-link-color:            #555;
    //** Hover color for dropdown links.
    --ion-dropdown-link-hover-color:      darken(#4D4D4D, 5%);
    // --ion-dropdown-link-hover-color:   LightenDarkenColor(var(--ion-gray-dark), 5%);

    //** Hover background for dropdown links.
    --ion-dropdown-link-hover-bg:         #f5f5f5;

    //** Active dropdown menu item text color.
    --ion-dropdown-link-active-color: var(--ion-component-active-color);
    //** Active dropdown menu item background color.
    --ion-dropdown-link-active-bg:        transparent;

    //** Disabled dropdown menu item background color.
    --ion-dropdown-link-disabled-color:   var(--ion-gray-light);

    //** Text color for headers within dropdown menus.
    --ion-dropdown-header-color:      var(--ion-gray-light);

    // Note: Deprecated --ion-dropdown-caret-color as of v3.1.0
    --ion-dropdown-caret-color:           #000;


    //-- Z-index master list
    //
    // Warning: Avoid customizing these values. They're used for a bird's eye view
    // of components dependent on the z-axis and are designed to all work together.
    //
    // Note: These variables are not generated into the Customizer.

    --ion-zindex-navbar:            1000;
    --ion-zindex-dropdown:          1000;
    --ion-zindex-popover:           1010;
    --ion-zindex-tooltip:           1030;
    --ion-zindex-navbar-fixed:      1030;
    --ion-zindex-modal-background:  1040;
    --ion-zindex-modal:             1050;


    //== Media queries breakpoints
    //
    //## Define the breakpoints at which your layout will change, adapting to different screen sizes.

    // Extra small screen / phone
    // Note: Deprecated --ion-screen-xs and --ion-screen-phone as of v3.0.1
    --ion-screen-xs:                  480px;
    --ion-screen-xs-min:          var(--ion-screen-xs);
    --ion-screen-phone:           var(--ion-screen-xs-min);

    // Small screen / tablet
    // Note: Deprecated --ion-screen-sm and --ion-screen-tablet as of v3.0.1
    --ion-screen-sm:                  768px;
    --ion-screen-sm-min:          var(--ion-screen-sm);
    --ion-screen-tablet:          var(--ion-screen-sm-min);

    // Medium screen / desktop
    // Note: Deprecated --ion-screen-md and --ion-screen-desktop as of v3.0.1
    --ion-screen-md:                  992px;
    --ion-screen-md-min:          var(--ion-screen-md);
    --ion-screen-desktop:         var(--ion-screen-md-min);

    // Large screen / wide desktop
    // Note: Deprecated --ion-screen-lg and --ion-screen-lg-desktop as of v3.0.1
    --ion-screen-lg:                  1200px;
    --ion-screen-lg-min:          var(--ion-screen-lg);
    --ion-screen-lg-desktop:      var(--ion-screen-lg-min);

    // So media queries dont overlap when required, provide a maximum
    --ion-screen-xs-max:              (var(--ion-screen-sm-min) - 1);
    --ion-screen-sm-max:              (var(--ion-screen-md-min) - 1);
    --ion-screen-md-max:              (var(--ion-screen-lg-min) - 1);


    //== Grid system
    //
    //## Define your custom responsive grid.

    //** Number of columns in the grid.
    --ion-grid-columns:              12;
    //** Padding between columns. Gets divided in half for the left and right.
    --ion-grid-gutter-width:         30px;
    // Navbar collapse
    //** Point at which the navbar becomes uncollapsed.
    --ion-grid-float-breakpoint: var(--ion-screen-sm-min);
    //** Point at which the navbar begins collapsing.
    --ion-grid-float-breakpoint-max: (var(--ion-grid-float-breakpoint) - 1);


    //== Navbar
    //
    //##

    // Basics of a navbar
    --ion-navbar-height:                    50px;
    --ion-navbar-margin-bottom:         var(--ion-line-height-computed);
    --ion-navbar-border-radius:             0;
    --ion-navbar-padding-horizontal:        floor((--ion-grid-gutter-width / 2));
    --ion-navbar-padding-vertical:          ((var(--ion-navbar-height) - var(--ion-line-height-computed)) / 2);
    --ion-navbar-collapse-max-height:       340px;

    --ion-navbar-default-color:             #777;
    --ion-navbar-default-bg:                #f8f8f8;
    --ion-navbar-default-border:            darken(#f8f8f8, 6%);

    // Navbar links
    --ion-navbar-default-link-color:            var(--ion-gray);
    --ion-navbar-default-link-hover-color:          #333;
    --ion-navbar-default-link-hover-bg:             transparent;
    --ion-navbar-default-link-active-color:     var(--ion-link-color);
    --ion-navbar-default-link-active-bg:            darken(#f8f8f8, 6%);
    // --ion-navbar-default-link-active-bg:         LightenDarkenColor(var(--ion-navbar-default-bg), 6.5%);
    --ion-navbar-default-link-disabled-color:       #ccc;
    --ion-navbar-default-link-disabled-bg:          transparent;

    // Navbar brand label
    --ion-navbar-default-brand-color:           var(--ion-navbar-default-link-color);
    --ion-navbar-default-brand-hover-color:         darken(#555, 10%);
    // --ion-navbar-default-brand-hover-color:         LightenDarkenColor(var(--ion-navbar-default-brand-color), 10%);
    --ion-navbar-default-brand-hover-bg:            transparent;

    // Navbar toggle
    --ion-navbar-default-toggle-hover-bg:           #ddd;
    --ion-navbar-default-toggle-icon-bar-bg:        #888;
    --ion-navbar-default-toggle-border-color:       #ddd;


    // Inverted navbar
    // Reset inverted navbar basics
    --ion-navbar-inverse-color:                  var(--ion-gray-light);
    --ion-navbar-inverse-bg:                         #222;
    --ion-navbar-inverse-border:                     darken(#222, 10%);
    // --ion-navbar-inverse-border:                     LightenDarkenColor(var(--ion-navbar-inverse-bg), 10%);

    // Inverted navbar links
    --ion-navbar-inverse-link-color:             var(--ion-gray-light);
    --ion-navbar-inverse-link-hover-color:           #fff;
    --ion-navbar-inverse-link-hover-bg:              transparent;
    --ion-navbar-inverse-link-active-color:      var(--ion-navbar-inverse-link-hover-color);
    --ion-navbar-inverse-link-active-bg:             darken(#222, 10%);
    --ion-navbar-inverse-link-disabled-color:        #444;
    --ion-navbar-inverse-link-disabled-bg:           transparent;

    // Inverted navbar brand label
    --ion-navbar-inverse-brand-color:            var(--ion-navbar-inverse-link-color);
    --ion-navbar-inverse-brand-hover-color:          #fff;
    --ion-navbar-inverse-brand-hover-bg:             transparent;

    // Inverted navbar toggle
    --ion-navbar-inverse-toggle-hover-bg:            #333;
    --ion-navbar-inverse-toggle-icon-bar-bg:         #fff;
    --ion-navbar-inverse-toggle-border-color:        #333;


    //== Navs
    //
    //##

    //=== Shared nav styles
    --ion-nav-link-padding:                          10px 15px;
    --ion-nav-link-hover-bg:                         transparent;

    --ion-nav-disabled-link-color:               var(--ion-gray-light);
    --ion-nav-disabled-link-hover-color:         var(--ion-gray-light);

    --ion-nav-open-link-hover-color:                 #fff;

    //== Tabs
    --ion-nav-tabs-border-color:                     transparent;

    --ion-nav-tabs-link-hover-border-color:          transparent;

    --ion-nav-tabs-active-link-hover-bg:             transparent;
    --ion-nav-tabs-active-link-hover-color:      var(--ion-gray);
    --ion-nav-tabs-active-link-hover-border-color:   transparent;

    --ion-nav-tabs-justified-link-border-color:            #ddd;
    --ion-nav-tabs-justified-active-link-border-color: var(--ion-body-bg);

    //== Pills
    --ion-nav-pills-border-radius:                  var(--ion-border-radius-base);
    --ion-nav-pills-active-link-hover-bg:        var(--ion-component-active-bg);
    --ion-nav-pills-active-link-hover-color:     var(--ion-component-active-color);


    //== Pagination
    //
    //##

    --ion-pagination-color:                 var(--ion-link-color);
    --ion-pagination-bg:                        #fff;
    --ion-pagination-border:                    #ddd;

    --ion-pagination-hover-color:           var(--ion-link-hover-color);
    --ion-pagination-hover-bg:              var(--ion-gray-lighter);
    --ion-pagination-hover-border:              #ddd;

    --ion-pagination-active-color:              #fff;
    --ion-pagination-active-bg:                 var(--ion-brand-primary);
    --ion-pagination-active-border:             var(--ion-brand-primary);

    --ion-pagination-disabled-color:        var(--ion-gray-light);
    --ion-pagination-disabled-bg:               #fff;
    --ion-pagination-disabled-border:           #ddd;


    //== Pager
    //
    //##

    --ion-pager-bg:                         var(--ion-pagination-bg);
    --ion-pager-border:                     var(--ion-pagination-border);
    --ion-pager-border-radius:                  15px;

    --ion-pager-hover-bg:                   var(--ion-pagination-hover-bg);

    --ion-pager-active-bg:                  var(--ion-pagination-active-bg);
    --ion-pager-active-color:               var(--ion-pagination-active-color);

    --ion-pager-disabled-color:             var(--ion-pagination-disabled-color);


    //== Jumbotron
    //
    //##

    --ion-jumbotron-padding:              30px;
    --ion-jumbotron-color:                inherit;
    --ion-jumbotron-bg:               var(--ion-gray-lighter);
    --ion-jumbotron-heading-color:        inherit;
    --ion-jumbotron-font-size:            ceil((var(--ion-font-size-base) * 1.5));


    //== Form states and alerts
    //
    //## Define colors for form feedback states and, by default, alerts.

    --ion-state-success-text:             darken(#2FD189, 15%);
    // --ion-state-success-text:             LightenDarkenColor(var(--ion-brand-success), -15);
    --ion-state-success-bg:               lighten(#2FD189, 40%);
    // --ion-state-success-bg:               LightenDarkenColor(var(--ion-brand-success), 40);
    --ion-state-success-border:           transparent;

    --ion-state-info-text:                lighten(#4D4D4D, 5%);
    // --ion-state-info-text:                LightenDarkenColor(var(--ion-text-color), 5);
    --ion-state-info-bg:                  lighten(#459EEC, 38%);
    // --ion-state-info-bg:                  LightenDarkenColor(var(--ion-brand-info), 38);
    --ion-state-info-border:              darken(#eee, 8%);
    // --ion-state-info-border:              LightenDarkenColor(var(--ion-gray-lighter), -8);

    --ion-state-warning-text:             darken(#F29400, 5%);
    // --ion-state-warning-text:             LightenDarkenColor(var(--ion-brand-warning), -5);
    --ion-state-warning-bg:               #fcf8e3;
    --ion-state-warning-border:           darken(#fcf8e3, 10%);
    // --ion-state-warning-border:           LightenDarkenColor(adjust-hue(var(--ion-state-warning-bg), -10), -5);

    --ion-state-danger-text:              var(--ion-brand-danger);
    --ion-state-danger-bg:                #f2dede;
    --ion-state-danger-border:            darken(#E94D4C, 10%);


    //== Tooltips
    //
    //##

    //** Tooltip max width
    --ion-tooltip-max-width:           200px;
    //** Tooltip text color
    --ion-tooltip-color:               #fff;
    //** Tooltip background color
    --ion-tooltip-bg:              var(--ion-gray-darker);
    --ion-tooltip-opacity:             1;

    //** Tooltip arrow width
    --ion-tooltip-arrow-width:         5px;
    //** Tooltip arrow color
    --ion-tooltip-arrow-color:     var(--ion-tooltip-bg);


    //== Popovers
    //
    //##

    //** Popover body background color
    --ion-popover-bg:                          #fff;
    //** Popover maximum width
    --ion-popover-max-width:                   276px;
    //** Popover border color
    --ion-popover-border-color:                rgba(0,0,0,.1);
    //** Popover fallback border color
    --ion-popover-fallback-border-color:       #ccc;

    //** Popover title background color
    --ion-popover-title-bg:                    transparent;

    //** Popover arrow width
    --ion-popover-arrow-width:                 7px;
    //** Popover arrow color
    --ion-ion-popover-arrow-color:                 #fff;

    //** Popover outer arrow width
    --ion-popover-arrow-outer-width:           (var(--ion-popover-arrow-width) + 1);
    //** Popover outer arrow color
    --ion-ion-popover-arrow-outer-color:           rgba(0,0,0,.1);
    //** Popover outer arrow fallback color
    --ion-ion-popover-arrow-outer-fallback-color:  #999;


    //== Labels
    //
    //##

    //** Default label background color
    --ion-label-default-bg:            #eee;
    //** Primary label background color
    --ion-label-primary-bg:            var(--ion-brand-primary);
    //** Success label background color
    --ion-label-success-bg:        var(--ion-brand-success);
    //** Info label background color
    --ion-label-info-bg:           var(--ion-brand-info);
    //** Warning label background color
    --ion-label-warning-bg:        var(--ion-brand-warning);
    //** Danger label background color
    --ion-label-danger-bg:             var(--ion-brand-danger);

    //** Default label text color
    --ion-label-color:             var(--ion-gray-light);
    //** Default text color of a linked label
    --ion-label-link-hover-color:      #fff;


    //== Modals
    //
    //##

    //** Padding applied to the modal body
    --ion-modal-inner-padding:         20px;

    //** Padding applied to the modal title
    --ion-modal-title-padding:         15px;
    //** Modal title line-height
    --ion-modal-title-line-height: var(--ion-line-height-base);

    //** Background color of modal content area
    --ion-modal-content-bg:                             #fff;
    //** Modal content border color
    --ion-modal-content-border-color:                   transparent;
    //** Modal content border color **for IE8**
    --ion-modal-content-fallback-border-color:          transparent;

    //** Modal backdrop background color
    --ion-modal-backdrop-bg:           #000;
    //** Modal backdrop opacity
    --ion-modal-backdrop-opacity:      .3;
    //** Modal header border color
    --ion-modal-header-border-color:   #E2E2E2;
    //** Modal footer border color
    --ion-modal-footer-border-color:   var(--ion-modal-header-border-color);

    --ion-modal-lg:                    900px;
    --ion-modal-md:                    600px;
    --ion-modal-sm:                    300px;


    //== Alerts
    //
    //## Define alert colors, border radius, and padding.

    --ion-alert-padding:               15px;
    --ion-alert-border-radius:         0;
    --ion-alert-link-font-weight:      bold;

    --ion-alert-success-bg:        var(--ion-state-success-bg);
    --ion-alert-success-text:      var(--ion-state-success-text);
    --ion-alert-success-border:    var(--ion-state-success-border);

    --ion-alert-info-bg:           var(--ion-state-info-bg);
    --ion-alert-info-text:         var(--ion-state-info-text);
    --ion-alert-info-border:       var(--ion-state-info-border);

    --ion-alert-warning-bg:        var(--ion-state-warning-bg);
    --ion-alert-warning-text:      var(--ion-state-warning-text);
    --ion-alert-warning-border:    var(--ion-state-warning-border);

    --ion-alert-danger-bg:         var(--ion-state-danger-bg);
    --ion-alert-danger-text:       var(--ion-state-danger-text);
    --ion-alert-danger-border:     var(--ion-state-danger-border);


    //== Progress bars
    //
    //##

    //** Background color of the whole progress component
    --ion-progress-bg:                 #efefef;
    //** Progress bar text color
    --ion-progress-bar-color:          #fff;

    //** Default progress bar color
    --ion-progress-bar-bg:             var(--ion-brand-primary);
    //** Success progress bar color
    --ion-progress-bar-success-bg: var(--ion-brand-success);
    //** Warning progress bar color
    --ion-progress-bar-warning-bg: var(--ion-brand-warning);
    //** Danger progress bar color
    --ion-progress-bar-danger-bg:      var(--ion-brand-danger);
    //** Info progress bar color
    --ion-progress-bar-info-bg:    var(--ion-brand-info);


    //== List group
    //
    //##

    //** Background color on `.list-group-item`
    --ion-list-group-bg:                 #fff;
    //** `.list-group-item` border color
    --ion-list-group-border:             #ddd;
    //** List group border radius
    --ion-list-group-border-radius:     var(--ion-border-radius-base);

    //** Background color of single list elements on hover
    --ion-list-group-hover-bg:           #f5f5f5;
    //** Text color of active list elements
    --ion-list-group-active-color:   var(--ion-text-color);
    //** Background color of active list elements
    --ion-list-group-active-bg:          #EBF7FB;
    //** Border color of active list elements
    --ion-list-group-active-border:      #BDE4F0;
    --ion-list-group-active-text-color:  var(--ion-text-color);

    --ion-list-group-link-color:         #555;
    --ion-list-group-link-heading-color: #333;


    //== Panels
    //
    //##

    --ion-panel-bg:                    #fff;
    --ion-panel-body-padding:          15px;
    --ion-panel-border-radius:        var(--ion-border-radius-base);

    //** Border color for elements within panels
    --ion-panel-inner-border:          #ddd;
    --ion-panel-footer-bg:             #f5f5f5;

    --ion-panel-default-text:      var(--ion-gray-dark);
    --ion-panel-default-border:        #ddd;
    --ion-panel-default-heading-bg:    #f5f5f5;

    --ion-panel-primary-text:          #fff;
    --ion-panel-primary-border:        var(--ion-brand-primary);
    --ion-panel-primary-heading-bg:    var(--ion-brand-primary);

    --ion-panel-success-text:      var(--ion-state-success-text);
    --ion-panel-success-border:    var(--ion-state-success-border);
    --ion-panel-success-heading-bg:    var(--ion-state-success-bg);

    --ion-panel-info-text:         var(--ion-state-info-text);
    --ion-panel-info-border:       var(--ion-state-info-border);
    --ion-panel-info-heading-bg:   var(--ion-state-info-bg);

    --ion-panel-warning-text:      var(--ion-state-warning-text);
    --ion-panel-warning-border:    var(--ion-state-warning-border);
    --ion-panel-warning-heading-bg:    var(--ion-state-warning-bg);

    --ion-panel-danger-text:       var(--ion-state-danger-text);
    --ion-panel-danger-border:     var(--ion-state-danger-border);
    --ion-panel-danger-heading-bg: var(--ion-state-danger-bg);


    //== Thumbnails
    //
    //##

    //** Padding around the thumbnail image
    --ion-thumbnail-padding:           4px;
    //** Thumbnail background color
    --ion-thumbnail-bg:            var(--ion-body-bg);
    //** Thumbnail border color
    --ion-thumbnail-border:            #ddd;
    //** Thumbnail border radius
    --ion-thumbnail-border-radius:    var(--ion-border-radius-base);

    //** Custom text color for thumbnail captions
    --ion-thumbnail-caption-color: var(--ion-text-color);
    //** Padding around the thumbnail caption
    --ion-thumbnail-caption-padding:   9px;


    //== Wells
    //
    //##

    --ion-well-bg:                     #fafbfc;
    --ion-well-border:                 darken(#fafbfc, 7%);
    // --ion-well-border:                 LightenDarkenColor(--ion-well-bg, -7);


    //== Badges
    //
    //##

    --ion-badge-color:                 #fff;
    //** Linked badge text color on hover
    --ion-badge-link-hover-color:      #fff;
    --ion-badge-bg:                var(--ion-gray-light);

    //** Badge text color in active nav link
    --ion-badge-active-color:      var(--ion-link-color);
    //** Badge background color in active nav link
    --ion-badge-active-bg:             #fff;

    --ion-badge-font-weight:           bold;
    --ion-badge-line-height:           1;
    --ion-badge-border-radius:         10px;


    //== Breadcrumbs
    //
    //##

    --ion-breadcrumb-padding-vertical:   8px;
    --ion-breadcrumb-padding-horizontal: 15px;
    //** Breadcrumb background color
    --ion-breadcrumb-bg:                 transparent;
    //** Breadcrumb text color
    --ion-breadcrumb-color:              lighten(#4D4D4D, 55%);
    // --ion-breadcrumb-color:              LightenDarkenColor(--ion-text-color, 55);
    //** Text color of current page in the breadcrumb
    --ion-breadcrumb-active-color:   var(--ion-text-color);
    //** Textual separator for between breadcrumb elements
    --ion-breadcrumb-separator:          "9";


    //== Carousel
    //
    //##

    --ion-carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

    --ion-carousel-control-color:                      #fff;
    --ion-carousel-control-width:                      15%;
    --ion-carousel-control-opacity:                    .5;
    --ion-carousel-control-font-size:                  20px;

    --ion-carousel-indicator-active-bg:                #fff;
    --ion-carousel-indicator-border-color:             #fff;

    --ion-carousel-caption-color:                      #fff;


    //== Close
    //
    //##

    --ion-close-font-weight:           normal;
    --ion-close-color:             var(--ion-text-color);
    --ion-close-text-shadow:           none;


    //== Code
    //
    //##

    --ion-code-color:                  #c7254e;
    --ion-code-bg:                     #f9f2f4;

    --ion-kbd-color:                   #fff;
    --ion-kbd-bg:                      #333;

    --ion-pre-bg:                      #f5f5f5;
    --ion-pre-color:               var(--ion-gray-dark);
    --ion-pre-border-color:            #ccc;
    --ion-pre-scrollable-max-height:   340px;


    //== Type
    //
    //##

    //** Text muted color
    --ion-text-muted:              var(--ion-gray-light);
    //** Abbreviations and acronyms border color
    --ion-abbr-border-color:       var(--ion-gray-light);
    //** Headings small color
    --ion-headings-small-color:    var(--ion-gray-light);
    //** Blockquote small color
    --ion-blockquote-small-color:  var(--ion-gray-light);
    //** Blockquote border color
    --ion-blockquote-border-color: var(--ion-gray-lighter);
    //** Page header border color
    --ion-page-header-border-color:    transparent;


    //== Miscellaneous
    //
    //##

    //** Horizontal line color.
    --ion-hr-border:               var(--ion-gray-lighte);

    //** Horizontal offset for forms and lists.
    --ion-component-offset-horizontal: 180px;


    //== Container sizes
    //
    //## Define the maximum width of `.container` for different screen sizes.

    // Small screen / tablet
    --ion-container-tablet:             ((720px + var(--ion-grid-gutter-width)));
    //** For `--ion-screen-sm-min` and up.
    --ion-container-sm:             var(--ion-container-tablet);

    // Medium screen / desktop
    --ion-container-desktop:            ((940px + var(--ion-grid-gutter-width)));
    //** For `--ion-screen-md-min` and up.
    --ion-container-md:             var(--ion-container-desktop);

    // Large screen / wide desktop
    --ion-container-large-desktop:      ((1140px + var(--ion-grid-gutter-width)));
    //** For `--ion-screen-lg-min` and up.
    --ion-container-lg:             var(--ion-container-large-desktop);
}