//
// Labels
// --------------------------------------------------

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: var(--ion-label-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;

  // [converter] extracted a& to a.label

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  &:hover,
  &:focus {
    color: var(--ion-label-link-hover-color);
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  @include label-variant(#eee);
}

.label-primary {
  @include label-variant(#459EEC);
}

.label-success {
  @include label-variant(#2FD189);
}

.label-info {
  @include label-variant(#459EEC);
}

.label-warning {
  @include label-variant(#F29400);
}

.label-danger {
  @include label-variant(#E94D4C);
}
