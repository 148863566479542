//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: var(--ion-btn-font-weight);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  @include button-size(var(--ion-padding-base-vertical), var(--ion-padding-base-horizontal), var(--ion-font-size-base), var(--ion-line-height-base), var(--ion-btn-border-radius-base));
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: var(--ion-btn-default-color);
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
    outline: 0;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: var(--ion-cursor-disabled);
    @include opacity(.65);
    @include box-shadow(none);
  }

  // [converter] extracted a& to a.btn
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant(#2FD189, #fff, #ccc);
}
.btn-primary {
  @include button-variant(#fff, #2FD189, transparent);
}
// Success appears as green
.btn-success {
  @include button-variant(#fff, #2FD189, #2FD189);
}
// Info appears as blue-green
.btn-info {
  @include button-variant(#fff, #459EEC, #459EEC);
}
// Warning appears as orange
.btn-warning {
  @include button-variant(#fff, #F29400, #F29400);
}
// Danger and error appear as red
.btn-danger {
  @include button-variant(#fff, #E94D4C, #E94D4C);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  font-weight: 400;
  color: var(--ion-link-color);
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: var(--ion-link-hover-color);
    text-decoration: var(--ion-link-hover-decoration);
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: var(--ion-btn-link-disabled-color);
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(var(--ion-padding-large-vertical), var(--ion-padding-large-horizontal), var(--ion-font-size-large), var(--ion-line-height-large), var(--ion-btn-border-radius-large));
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size(var(--ion-padding-small-vertical), var(--ion-padding-small-horizontal), var(--ion-font-size-small), var(--ion-line-height-small), var(--ion-btn-border-radius-small));
}
.btn-xs {
  @include button-size(var(--ion-padding-xs-vertical), var(--ion-padding-xs-horizontal), var(--ion-font-size-small), var(--ion-line-height-small), var(--ion-btn-border-radius-small));
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
