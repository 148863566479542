@import "../../../src/assets/scss/variables";
@import "../../../src/assets/scss/mixins";
@import "../../../src/assets/scss/buttons";
@import "../../../src/assets/scss/opacity";
@import "../../../src/assets/scss/vendor-prefixes";
@import "../../../src/assets/scss/clearfix";
@import "../../assets/scss/flex";

body {
	// make room for the fixed header
	padding-top: var(--ion-header-height-sm);
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;
	background: #fff;

	@media (min-width: var(--ion-screen-sm-min)) {
		padding-top: var(--ion-header-height-lg);
	}
}


///////////////////////////////////////

// app specific settings

///////////////////////////////////////

.printix-client body {
	@extend .bg-contrast--mobile;
	min-height: 100vh;
}

.printix-admin body {
	@extend .bg-contrast--mobile;
}



///////////////////////////////////////

// page backgrounds

///////////////////////////////////////

.bg-contrast body {
	background: var(--ion-brand-light-blue-accent);
}

.bg-contrast--mobile body {
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		background: var(--ion-brand-light-blue-accent);
	}
}

.bg-contrast--desktop body {
	background: #fff;

	@media screen and (min-width: var(--ion-grid-float-breakpoint-max)) {
		background: var(--ion-brand-light-blue-accent);
	}
}



///////////////////////////////////////

// header

///////////////////////////////////////

.header {
	border-bottom: 1px solid transparent;

	// currently there is no way to differentiate header markup for specific
	// pages, so until we implement some proper handling for layouts and nested
	// templates (UI router comes to mind), then this half-assed solution is
	// my only option * SIGH * "Angular is dead! Long live Angular!"

	// hopefully we'll be able to use these classes intellegently in the future.
	@extend .header--fixed;

	@media (max-width: var(--ion-screen-xs-max)) {
		border-color: var(--ion-separator-color);;
	}

	// trial bar stuff
	html.trial & {
		top: 35px;

		&.headroom--pinned 			{ transform:(translateY(0%)); }
		&.headroom--not-top 		{ transform:(translateY(-35px)); }
		&.headroom--top 			{  transition:(background 0.2s 0.2s, box-shadow 0.2s 0.2s, transform 0.2s); }
	}
}

.header--fixed {
	position: fixed;
	top: 0;
	z-index: var(--ion-zindex-navbar-fixed);
	width: 100%;
	background: var(--ion-body-bg);
	transition: (background 0.2s, box-shadow 0.2s, transform 0.2s);

	&.headroom--not-top {
		box-shadow:(0px 0px 6px 0px rgba(0,0,0,0.1));
		border-color: var(--ion-separator-color);;
	}
}

// more headaches caused by our lack of template control.
// used by guide templates to prevent background colors.
.header--transparent {
	transition:(background 0.2s, box-shadow 0.2s, transform 0.2s 0.2s);
	background: transparent;

	&.headroom--not-top {
		background: var(--ion-body-bg);
	}
}



///////////////////////////////////////

// Trial

///////////////////////////////////////

// make room for the trial-bar
.trial body {
	padding-top: 86px;

	@media (min-width: var(--ion-screen-sm-min)) {
		padding-top: 116px;
	}
}

// Header trial bar
.trial-bar {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	background: var(--ion-brand-primary);
	will-change: transform;
	transition:(transform 200ms linear);

	a {
		display: block;
		height: 35px;
		line-height: 32px;
		text-align: center;
		color: #fff;
	}
}

.trial-bar--danger {
	background: var(--ion-brand-danger);
}



a:hover, a:focus {
	text-decoration: none;
}

.hover-fix:hover,
[go-to-url]:hover {
	cursor: pointer;
}

.page-header {
  margin: 0;
  padding: 40px 20px 20px 20px !important;
}

.container {
	@media (max-width: var(--ion-screen-sm-max)) {
		width: auto;
	}

	@media only screen and (max-width: 767px) {
		.dashboard-row-container {
			margin-top: 20px;
		}
	}
}

img {
	max-width: 100%;
}



///////////////////////////////////////

// flex utilities

///////////////////////////////////////

.flex-container {
	@include flexbox();
}


.section--separate {
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		margin-bottom: 0.5em;
		background: #fff;
		box-shadow:(1px 1px 2px 0px rgba(0,0,0,0.05));
	}
}

.section--spaced {
	padding: 1em 0;

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		padding: 2em 0;
	}
}

.section__heading {
	margin-top: .5em;
	font-size: 1.6em;
	@extend .light;
}

.section__sub-heading {
	font-size: 1.1em;
	@extend .medium;

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		font-size: 1.3em;
	}
}

.main-content,
.content-section {
	background: #fff;
}

.section--center-xy {
	@extend .flex-container;
	min-height: calc(100vh - #{--ion-navbar-height});
	@include justify-content(center);
	@include align-items(center);
}

.mobile-full-width {
	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		margin-left: var(---ion-grid-gutter-width/2);
		margin-right: var(---ion-grid-gutter-width/2);
		padding-left: var(---ion-grid-gutter-width/2);
		padding-right: var(---ion-grid-gutter-width/2);
	}
}


///////////////////////////////////////

// Top bar

///////////////////////////////////////

.top-bar--bordered {
	border-bottom: 1px solid var(--ion-separator-color);;

	@media screen and (min-width: var(--ion-screen-sm-min)) {
		border-top: 1px solid var(--ion-separator-color);;
	}
}

.top-bar__inner {
	@extend .flex-container;
}

.top-bar__item {
	@include flex();
}

.top-bar--pulled-up {
	margin-top: -4em;
}


///////////////////////////////////////

// Action list

///////////////////////////////////////

// actions involving content specific editing/functionality (e.g. delete, refresh)

.action-list {
	position: relative;
	@extend .list-unstyled !optional;
	margin: 0;
	text-align: right;
	min-height: 50px;

	li {
		display: inline-block;
	}

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		min-height: auto;
		margin: 1em 0;
	}
}

.action-list--spaced {
	li + li {
		margin-left: 0.5em;
	}
}

.action-list__link {
	display: inline-block;
	padding: 15px 0 10px;

}

.action-list__btn {
	background-color: transparent;
	border: none;
	padding: 10px;
	margin: 0;
	outline: none;
	font-size: 1.3em;
	transition:(color 0.2s);

	&:hover {
		color: var(--ion-link-color);
	}
}


///////////////////////////////////////

// Quick search

///////////////////////////////////////

.quick-search {

	@media (max-width: var(--ion-screen-xs-max)) {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		left: -15px;
		right: -15px;
		top: 0;
		height: 50px;
		transition:(opacity 0.3s, visibility 0s 0.3s);
	}
}


.quick-search--active {

	@media (max-width: var(--ion-screen-xs-max)) {
		visibility: visible;
		opacity: 1;
		transition:(opacity 0.3s, visibility 0s);

		.quick-search__close {
			opacity: 1;
			@include translate(0,-50%);
			transition:(opacity 0.2s 0.2s, transform 0.2s 0.2s);
		}
	}
}


.quick-search__input {
	transition:(width 0.15s, transform 0.2s, opacity 0.2s, visibility 0s);
	padding-right: 2.8em;
	min-height: 45px;

	@media (max-width: var(--ion-screen-xs-max)) {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		min-height: 50px;
		border: 0;
		font-size: 1.1em;
	}

	&:focus {
		box-shadow:(none);
		border-color: var(--ion-input-border);
	}

	// desktop
	@media (min-width: var(--ion-screen-sm-min)) {
		width: 13em;

		&:focus {
			width: 20em;
		}
	}
}

.quick-search__input-hack {
	transition:(width 0.15s, transform 0.2s, opacity 0.2s, visibility 0s);
	padding-right: 2.8em;
	min-height: 45px;

	@media (max-width: var(--ion-screen-xs-max)) {
		position: absolute;
		right: 0;
		top: 0;
		min-height: 50px;
		border: 0;
		font-size: 1.1em;
		width: 220px;
		right: 10px;
	}

	&:focus {
		box-shadow:(none);
		border-color: var(--ion-input-border);
	}

	// desktop
	@media (min-width: var(--ion-screen-sm-min)) {
		width: 13em;

		&:focus {
			width: 20em;
		}
	}
}


.quick-search__icon {
	@extend .action-list__btn;
	margin-right: -10px; // compensate for the horz padding

	// desktop
	@media (min-width: var(--ion-screen-sm-min)) {
		position: absolute;
		@include center(y);
		right: 0;
		margin: 0;
		padding: 12px 10px;
		line-height: 1;
		background: none;
		font-size: 1.3em;
	}
}

.quick-search__close {
	@extend .action-list__btn;
	position: absolute;
	opacity: 0;
	top: 50%;
	right: 5px; // horz padding is 10px
	z-index: 2;
	@include translate(0,-20%);
	transition:(opacity 0s, transform 0.2s);
}



///////////////////////////////////////

// page actions

///////////////////////////////////////


// primary buttons and links (submit, cancel etc.)
.page-actions {
	padding-top: 1.3em;
	padding-bottom: 1.3em;
	background: #fff;
	@include clearfix;
	transition:(opacity 0.25s, transform 0.25s);

	// no need for the container element if page-actions are not fixed positioned
	&:not(.page-actions--fixed) .container {
		width: auto;
		padding: 0;
	}

	.btn {
		min-width: 12em;
		font-size: 1.1em;
		padding: 0.7em 3em;
		background-color: var(--ion-color-green1);
	}

	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {

		> .container {
			width: auto;
			padding: 0;
		}

		.btn-primary {
			display: block;
			width: 100%;
			min-width: auto;
			font-size: 1.1em;
		}

		&.page-actions--fixed {
			padding-top: 0;
			padding-bottom: 0;
			border: 0;
			box-shadow:(0px 0px 6px 0px rgba(0,0,0,0.2));


			.btn-primary {
				border-radius: 0;
			}
		}
	}

	@media screen and (min-width: var(--ion-grid-float-breakpoint)) {
		.btn-primary:not(.btn-block) {
			float: right;
		}
	}
}


// use this class on a parent element to add bottom padding
// to the page, so that content doesn't get hidden behind
// the fixed page-actions element.

// TODO: this should be replaced by a flexbox setup to suck a little less!
.page-actions-fixed {
	padding-bottom: 7em;
}

.page-actions--fixed {
	position: fixed;
	// z-index: var(--ion-zindex-navbar-fixed); // not sure if dropdowns should go on top of the fixed page-actions or not
	width: 100%;
	left: 0;
	bottom: 40px;
	border-top: 1px solid var(--ion-separator-color);
}

.page-actions--hidden {
	display: none;
	opacity: 0;
	@include translate(0, 100%);
	padding: 0;
}

.page-actions__section {

	@media screen and (max-width: var(--ion-grid-float-breakpoint-max)) {
		padding: 0.7em 0;
	}
}

///////////////////////////////////////
// buttons
///////////////////////////////////////

.btn {
	transition:(background-color 0.2s, border 0.2s, color 0.2s);
}

.btn--default {
	border-color: var(--ion-btn-default-border);
	background: #fff;
	color: var(--ion-text-color);

	&:hover,
	&.focus,
	&:active,
	.open > &.dropdown-toggle {
		border-color: var(--ion-brand-primary);
		background-color: var(--ion-brand-light-blue);
	}
	//
	// &:focus {
	// 	@include tab-focus;
	// }
}

.btn-primary:hover {
	background-color: var(--ion-color-green1, 5%);
	border-color: var(--ion-color-green1, 5%);
}

.btn-primary--bordered {
	@include button-variant(#2FD189, transparent, #2FD189);

	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&:active:hover {
		background-color: var(--ion-color-green1);
		border-color: var(--ion-color-green1);
		color: #fff;
	}
}


.btn--secondary {
	background-color: transparent;
	border-color: var(--ion-brand-primary);
	color: --ion-brand-primary-light;

	&:hover:not(.disabled) {
		background-color: var(--ion-brand-primary);
		color: #fff;
	}
}

.btn--secondary-danger {
	background-color: transparent;
	border-color: var(--ion-brand-danger);
	color: var(--ion-brand-danger-light);

	&:hover:not(.disabled) {
		background-color: var(--ion-brand-danger);
		color: #fff;
	}
}

.btn-pop {
	/* button bg: */
	border: 1px solid rgba(220,220,220,0.50);
	box-shadow:(1px 1px 5px 0px rgba(0,0,0,0.15));
	border-radius: 6px;

	&:focus,
	&.focus,
	&:active,
	&.active,
	&:hover {
		box-shadow:(1px 1px 5px 0px rgba(0,0,0,0.15));
	}
}

.btn-link:hover {
	text-decoration: none;
}

.btn [class^="icon-"]:before,
.btn [class*=" icon-"]:before {
	vertical-align: -15%;
	margin-left: 0.2em;
	margin-right: 0.2em;
}

.btn__icon [class^="icon-"]:before,
.btn [class*=" icon-"]:before {
	vertical-align: -15%;
	margin-right: 0.6em;
}

.btn--multiline {
	position: relative;
	padding-top: 5px;
	padding-bottom: 5px;

	.icon {
		position: absolute;
		left: 1em;
		@include center(y);
		transition:(transform 0.2s);
	}
}

.btn__label {
	display: inline-block;
	text-align: left;
}

.btn__help-text {
	display: block;
	font-size: 0.8em;
	@include opacity(0.7);
	font-weight: normal;
	line-height: 1.5em;
}


.link-follow-me {
	font-weight: 800;
	font-size: 0.75em;
	text-transform: uppercase;
	letter-spacing: 0.05em;

	&::after {
		@extend .icon;
		content: "\e933";
  		font-size: 80%;
		margin-left: .4em;
	}
}


///////////////////////////////////////
// modals
///////////////////////////////////////

.close {
	padding: 10px;

	@include opacity(0.7);

	&:hover {
		@include opacity(1);
	}
}

button.close {
	font-size: 30px;
}

.modal-title {
	// max-width: 70%;
	font-size: 1.25em;
	font-weight: 500;
	color: var(--ion-text-color);
	@include center-block;
	text-align: center;
}


.modal-content {
	box-shadow:(0 3px 15px rgba(0,0,0,.3));
}

.modal-footer {
	@media screen and (min-width: var(--ion-screen-sm)) {
		.btn {
			min-width: 8em;
		}
	}
}

.modal--fullscreen {
	background: #fff;
	@include translate3d(0,0,0);

	.modal-header {
		text-align: center;
		border-bottom: 1px solid var(--ion-separator-color);
	}

	.modal-body {
		max-width: var(--ion-container-md);
		@include center-block;
		padding: 0 var(--ion-grid-gutter-width /2 var(--ion-grid-gutter-width /2));
	}

	.modal-footer {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		background: var(--ion-gray-lightest);
		border-top: 1px solid var(--ion-separator-color);;
		padding: var(--ion-grid-gutter-width /2);
	}

	.close {
		margin-top: 7px;
		font-size: 1.1em;
		color: var(--ion-link-color);

		&:hover {
			color: var(--ion-link-hover-color);
		}
	}

	.modal-dialog {
		position: absolute;
		margin: 0;
		width: 100%;
		min-height: 100%;
	}

	.modal-content {
		height: 100%;
		border-radius: 0;
		box-shadow:(none);
		border: 0;
	}
}

.modal-backdrop.modal-backdrop--fullscreen {
	background: transparent;
}

.modal--slide.fade {

	.modal-dialog {
		transition:-transform(0.35s ease-out);
	}

	.modal-dialog {
		@include translate(0, 100%);
	}

	&.in .modal-dialog {
		@include translate(0, 0);
	}
}


///////////////////////////////////////
// illustrations
///////////////////////////////////////

.illustration {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

///////////////////////////////////////
// wells
///////////////////////////////////////

.well {
	box-shadow:(none);
}

.well--separate {
	margin-top: 3em;
}


///////////////////////////////////////
// drop-downs
///////////////////////////////////////


.dropdown-menu {
	z-index: 11000;

}

.dropdown-menu p {
	width: 150px;
	margin: 0px;
	word-wrap: break-word;
}
.dropdown-toggle {
	color: var(--ion-text-color);
}

.dropdown-toggle__text {
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	text-overflow: ellipsis;
	width: 80%;
}

.dropdown--block {

	.dropdown-toggle {
		width: 100%;
		text-align: left;
	}

	.caret {
		float: right;
	}

	.dropdown-menu {
		width: 100%;

		a {
			@include text-overflow;
		}
	}
}

.dropdown-menu__icon {
	margin: 0 12px 0 0px;
	font-size: 1.2em;
	float: left;
}

.dropdown-menu__item--delete {
	position: relative;

	> a {
		padding-right: 50px !important;
	}
}

.dropdown-menu__item--modify-delete {
	position: relative;

	> a {
		padding-right: 100px !important;
	}
}

.dropdown-menu__delete {
	position: absolute;
	right: 0;
	@include center(y);
	padding: 10px 15px;
	transition:(opacity 0.2s);

	// show on hover on non-touch devices
	.no-touchevents & {
		opacity: 0;
	}

	.no-touchevents .dropdown-menu a:hover & {
		opacity: 1;
		transition:(opacity 0.2s 0.1s);
	}
}

.dropdown-menu__modify {
	position: absolute;
	right: 45px;
	@include center(y);
	padding: 10px 15px;
	transition:(opacity 0.2s);

	// show on hover on non-touch devices
	.no-touchevents & {
		opacity: 0;
	}

	.no-touchevents .dropdown-menu a:hover & {
		opacity: 1;
		transition:(opacity 0.2s 0.1s);
	}
}

.caret {
	border: 0;
	width: auto;
	height: auto;

	&::before {
		content: "\37";
		display: inline-block;
		vertical-align: -15%;
		line-height: 1;
		font-family: "icons";
	}
}

.dropdown-menu--large {
	min-width: 180px;
}

///////////////////////////////////////
// popovers
///////////////////////////////////////

.popover {
	box-shadow:(0 4px 12px rgba(0, 0, 0, 0.1));
}

.popover-title {
	padding: 15px 20px 5px;
	font-weight: 600;
}

.popover-content {
	padding: 0 20px 15px;
	font-size: 0.95em;

	ul {
		@extend .list-unstyled !optional;
		margin-bottom: 0;
	}

	li:before {
		content: "– ";
	}
}


///////////////////////////////////////
// empty state - centered (x and y)
///////////////////////////////////////

.empty-state {
	text-align: center;
	margin-top: -15%;
}

.empty-state__heading {
	font-size: 1.25em;
	font-weight: 500;
}

.empty-state__description {
	color: var(--ion-gray-light);
}


///////////////////////////////////////

// info-list (modal box)

///////////////////////////////////////

.info-list {
	@extend .list-unstyled !optional;
	width: 90%;
	margin-left: auto;
	margin-right: auto;

	li + li {
		margin-top: 1em;
	}

	@media screen and (min-width: var(--ion-screen-md-min)) {
		width: 90%;

		li + li {
			margin-top: 0.5em;
		}
	}
}

.info-list__header {
	color: var(--ion-text-dimmed);
}

.info-list__info {
	padding: 8px 12px;
	background: var(--ion-gray-lightest);
	border-radius: var(--ion-border-radius-base);
}


///////////////////////////////////////

// key-numbers

///////////////////////////////////////

.key-numbers {
}

.key-number > a {
	color: var(--ion-text-color);
	transition:(color 0.2s);

	&:hover {
		color: var(--ion-link-hover-color);
	}
}

.key-number__value {
	float: right;
}

.label-default {
	padding: .2em .6em;
	font-size: 1.2rem;
	font-weight: 500;
}

.label + .label {
	margin-left: 0.5em;
}


///////////////////////////////////////

// video container

///////////////////////////////////////
.video-container {
	@extend .embed-responsive !optional;
	box-shadow:(1px 11px 26px 0px rgba(0,0,0,0.15));
}

.video-container--spaced {
	margin: 2em 0;
}


///////////////////////////////////////

// badges

///////////////////////////////////////

.badge--success {
	color: var(--ion-color-green1-dark);
	background: var(--ion-color-green1);
}

.badge--danger {
	color: var(--ion-brand-danger);
	background: var(--ion-brand-danger);
}
